import React, { useEffect, useState } from 'react'
import c from './Compare.module.scss'
import Search from '../../search/Search'
import { useDispatch, useSelector } from 'react-redux'
import { initialStateInterface } from '../../../redux/weatherReducer'
import Table from '../table/Table'
import { ADD_TO_COMPARE } from '../../../redux/consts'
import { getTwoWeeksForecast } from '../../../functions'

interface Props {}

const Compare: React.FC<Props> = () => {
    const dispatch = useDispatch()
    const data = useSelector(
        (state: initialStateInterface) => state.compareCitiesList
    )
    const [weatherData, setWeatherData] = useState(data)
    useEffect(() => {
        setWeatherData(data)
    }, [data])

    const handleClick = async (lat: number, lon: number) => {
        try {
            const twoWeekData = await getTwoWeeksForecast(lat, lon)
            dispatch({
                type: ADD_TO_COMPARE,
                payload: {
                    ...twoWeekData,
                },
            })
        } catch (error) {
            console.error('error:', error)
        }
    }
    return (
        <div className={c.compare}>
            <h2 className={c.compare__title}>compare to other cities</h2>
            <Search onCityClick={handleClick} />
            <div className={c.compare__tables}>
                <div className={c.compare__tables_container}>
                    {weatherData?.map((item) => (
                        <div key={item.city.id}>
                            <Table data={item} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Compare
